.mainContainer {
  width: 1920px;
  height: 1080px;
  display: flex;
  justify-content: end;
  background-color: #007cb6;
  position: relative;
}

.menuContainer {
  width: 656px;
  /* height: 1080px; */
  padding: 2rem;
  text-align: center;
  background-color: #007cb6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* width: 609px; */
  position: absolute;
  left: 0;
  top: 0;
  height: 1016px;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 357px;
}
.languageSelector {
  align-self: flex-start;
}

.language {
  font-family: "ElMessiriBold";
  font-size: 1.5rem;
  padding: 0.2rem 0.6rem;
  cursor: pointer;
}

.language.active {
  opacity: .7;
}

.mainTitleContainer {
  margin: 1rem 0 0 0;
}

.mainTitle {
  font-size: 2.7rem;
  text-transform: uppercase;
  /* font-weight: 700; */
  font-family: "ElMessiriSemibold";
  line-height: 3rem;
}

.mainTitle2 {
  font-size: 2rem;
  font-weight: 700;
  font-family: "ElMessiriSemibold";
  
}

.mainTitle2.subtitle { 
    letter-spacing: 5px;
}
.mainTitle2.memories { 
    margin: 2px 0;
}

.subtitle {
    letter-spacing: 5px;
}

.description1 {
  font-size: 1.1rem;
  font-weight: 300;
  font-family: "ElMessiriRegular";
  margin: 1rem 0;
  font-style: italic;
  letter-spacing: 2px;
}

.mainLogoTop {
  width: 50px;
  height: 50px;
  margin: 1.2rem 0 0.3rem;
  align-self: center;
}

.mainLogoBottom {
  width: 50px;
  height: 50px;
  margin: 1.8rem 0;
  align-self: center;
}

.videosContainer {
  width: 1200px;
  height: 1080px;
  position: absolute;
  right: 0;
}

.menuVideoComponentContainer {
  z-index: 2;
  position: relative;
}

.placeholderVideoComponentContainer {
  z-index: 4;
  position: relative;
}

.videoOnTop {
  z-index: 3;
}

.questions {
  height: 458px;
}

.menu {
  font-size: 1.5rem;
  margin: 2.2rem 0;
  cursor: pointer;
  transition: opacity .5s ease;
  font-weight: 600;
  letter-spacing: .3px;
}

.menu:first-child {
  margin: 1rem 0 2.2rem 0;
}

.menu:last-child {
  margin: 2.2rem 0 0 0;
}

.menu.active {
  cursor: pointer;
  /* color: #d6eaf3; */
  opacity: .7;
  transition: opacity .5s ease;
}

.footer {
  height: 214px;
  display: flex;
  flex-direction: column;
}

.quizLabel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  cursor: pointer;
  opacity: 1;
  transition: opacity .2s ease;
}
.quizLabel.active {
  opacity: .7;
  transition: opacity .2s ease;
}
.quizLabelText {
  font-size: 2.4rem;
  text-transform: uppercase;
  margin: 0 1.2rem;
  font-family: "ElMessiriSemibold";
}

.quizLabelImg {
  height: 2rem;
  width: auto;
}

.transparentComponent {
  opacity: 0;
  transition: opacity 2s ease;
}

/* .videosContainer {
  width: 1200px;
  height: 1080px;
} */
.videoOutContainer {
  opacity: 1;
  transition: opacity .4s ease-in;
}

.transparentVideo {
  opacity: 0;
  transition: opacity .4s ease-in;
}

.unselectable {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.inactive {
  pointer-events: none;
}
