.videoPlaceholderContainer {
    width: 1200px;
    height: 1080px;
    background-color: gray;
    position: relative;
}

.placeholderVideo {
    width: 1200px;
    height: 1080px;
    position: absolute;
    opacity: 1;
    z-index: 2;
    /* transition: 2s ease-in; */
}



