.videoContainer {
    width: 65%;
    background-color: gray;
    position: relative;
    position: absolute;
    right: 0;
    width: 1200px;
    height: 1080px;
    transition: opacity 2s ease;
}

.placeholderImage {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 1;
    z-index: 2;
    transition: 2s ease-in;
}

.video {
    position: absolute;
    opacity: 1;
    transition: 2s ease-in;
}

.transparent {
    opacity: 0;
}

video::-webkit-media-text-track-display {
    color: white;
    font-size: 1em;
    line-height: 1.3em;
    position: absolut;
    bottom: 88%;
}