.galleryContainer {
    position: absolute;
    top: 0;
    right: 0;
    width: 1200px;
    height: 1080px;
    transition: opacity 2s ease;
    overflow-y: scroll;
}

.galleryContainer::-webkit-scrollbar {
    width: 0;               /* width of the entire scrollbar */
    height: 0;
}

.react-images__header_button--fullscreen {
    display: none!important;
}

.content_13hv8io {
    max-height: 1200px!important;
    max-width: unset!important;
}
.header_1tk9zku {
    width: 1880px!important;
}

