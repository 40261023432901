@font-face {
  font-family: "ArnoProRegular";    
  src: url("assets/fonts/ARNOPRO-REGULAR.OTF");
}

@font-face {
  font-family: "ArnoProItalic";    
  src: url("assets/fonts/ARNOPRO-ITALIC.OTF");
}

@font-face {
  font-family: "ArnoProBold";    
  src: url("assets/fonts/ARNOPRO-BOLD.OTF");
}

@font-face {
  font-family: "ElMessiriBold";    
  src: url("assets/fonts/MyFont-Bold.otf");
}

@font-face {
  font-family: "ElMessiriMedium";    
  src: url("assets/fonts/ElmessiriMedium-K7BOp.otf");
}
@font-face {
  font-family: "ElMessiriSemibold";    
  src: url("assets/fonts/ElmessiriSemibold-2O74K.otf");
}
@font-face {
  font-family: "ElMessiriRegular";    
  src: url("assets/fonts/MyFont-Regular.otf");
}

@font-face {
  font-family: "OpenSansLight";    
  src: url("assets/fonts/OpenSans-Light.ttf");
}


body {
  margin: 0;
  padding: 0;
  color: white;
  font-family: 'ElMessiriRegular';
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 0;
}

.react-images__header_button--fullscreen {
  display: none;
}

